import { UcpBootstrap } from '@tranzact/ucp-common';
import { defineCustomElements } from '@tranzact/standard-ui-components/loader';

defineCustomElements(window, {
  resourcesUrl: '/dist/',
});

UcpBootstrap.ucpBootstrapperStandard({
  loadTemplates: (moduleName: string) =>
    import(`./templates/${moduleName}/index`),

  loadComponents: (moduleName: string) =>
    import(`./components/${moduleName}/index`),
});
console.log('Global code running: All set!');
